import { StatusBar } from "expo-status-bar";
import { StyleSheet, Text, View } from "react-native";
import MapView from "react-native-map-clustering";
import { Marker, Region } from "react-native-maps";
import { useEffect, useState } from "react";
import { getZonesForLocationAPI } from "./apiCalls";
import { ZoneWithPrice } from "./types";
import * as Location from "expo-location";
import { LocationAccuracy } from "expo-location";
import PQueue from "p-queue/dist/index.js";
import { ZoneItem } from "./ZoneItem";
import { AppMapView } from "./AppMapView";

const queue = new PQueue({ concurrency: 1 });

const initialRegion: Region = {
  latitude: 26.7124327,
  longitude: -80.0526159,
  latitudeDelta: 0.00922 / 2,
  longitudeDelta: 0.00421 / 2,
};

export default function App() {
  const [zones, setZones] = useState<ZoneWithPrice[]>([]);
  const [userStartLocation, setUserStartLocation] = useState<Region | null>(
    null
  );
  const [openZone, setOpenZone] = useState<ZoneWithPrice | null>(null);

  useEffect(() => {
    (async () => {
      let { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== "granted") {
        return;
      }

      let location = await Location.getCurrentPositionAsync({
        accuracy: LocationAccuracy.Balanced,
      });

      setTimeout(() => {
        setUserStartLocation({
          latitude: location.coords.latitude,
          longitude: location.coords.longitude,
          latitudeDelta: initialRegion.latitudeDelta,
          longitudeDelta: initialRegion.longitudeDelta,
        });
      }, 500);
    })();
  }, []);

  async function getMarkers(inputRegion?: Region) {
    const zonesWithPrice = await getZonesForLocationAPI(
      inputRegion || initialRegion
    );

    const existingMarketIds = zones.map((zone) => zone.internalZoneCode);

    const uniqueResults = zonesWithPrice.filter(
      (result) => !existingMarketIds.includes(result.internalZoneCode)
    );

    setZones([...zones, ...uniqueResults]);
  }

  useEffect(() => {
    getMarkers();
  }, []);

  return (
    <View style={styles.container}>
      <StatusBar style="auto" />
      {userStartLocation ? (
        <AppMapView
          initialRegion={userStartLocation}
          queue={queue}
          getMarkers={getMarkers}
          zones={zones}
          setOpenZone={setOpenZone}
          openZone={openZone}
        />
      ) : (
        <View>
          <Text>Loading...</Text>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});
