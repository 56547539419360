import { StyleSheet, View } from "react-native";
import type { Region } from "react-native-maps";
import { ZoneItem } from "./ZoneItem";
import type PQueue from "p-queue/dist/index.js";
import { ZoneWithPrice } from "./types";
import GoogleMapReact from "google-map-react";

type Props = {
  initialRegion: Region;
  queue: PQueue;
  getMarkers: (region: Region) => void;
  zones: ZoneWithPrice[];
  openZone: ZoneWithPrice | null;
  setOpenZone: (zone: ZoneWithPrice | null) => void;
};

export function AppMapView({
  queue,
  getMarkers,
  initialRegion,
  zones,
  openZone,
  setOpenZone,
}: Props) {
  return (
    <View style={{ height: "100vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAGx1qdWIdDC0ru_gbMkKdRFwnLFXvbsGA" }}
        defaultCenter={{
          lat: initialRegion.latitude,
          lng: initialRegion.longitude,
        }}
        defaultZoom={18}
        onChange={async (ev) => {
          await queue.add(() =>
            getMarkers({
              latitude: ev.center.lat,
              longitude: ev.center.lng,
              longitudeDelta: initialRegion.longitudeDelta,
              latitudeDelta: initialRegion.latitudeDelta,
            })
          );
        }}
      >
        {zones.map((zone, i) => (
          <ZoneItem
            lat={zone.zoneInfo.latitude}
            lng={zone.zoneInfo.longitude}
            zone={zone}
            onPress={(event) => {
              if (openZone?.internalZoneCode === zone.internalZoneCode) {
                setOpenZone(null);
              } else {
                setOpenZone(zone);
              }
            }}
            isOpen={zone.internalZoneCode === openZone?.internalZoneCode}
          />
        ))}
      </GoogleMapReact>
    </View>
  );
}
