import { ZoneWithPrice } from "./types";
import styled from "styled-components/native";
import { GestureResponderEvent } from "react-native";

const PriceCircle = styled.TouchableOpacity`
  width: 60px;
  padding: 4px;
  border-radius: 6px;
  background-color: #0f4d0f;
`;

const CirclePrice = styled.Text`
  color: #fff;
  text-align: center;
  margin-top: 4px;
`;

const BlankContainer = styled.View`
  text-align: center;
`;

const LargeContainer = styled.TouchableOpacity`
  width: 200px;
  padding: 4px;
  border-radius: 8px;
  background-color: #fff;
  z-index: 1000;
  border-width: 2px;
  border-color: #999;
  box-shadow: 1px 2px 3px #666;
`;

const LocationName = styled.Text`
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  color: #999;
`;

const ZoneInfo = styled.Text`
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  color: #777;
`;

const Price = styled.Text`
  text-align: left;
  font-size: 20px;
  font-weight: 700;
  color: #666;
`;

const CircleZoneCode = styled.Text`
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: #666;
`;

const CircleZoneCodeContainer = styled.Text`
  padding: 2px;
  border-radius: 6px;
  background-color: #fff;
  text-align: center;
`;

type Props = {
  zone: ZoneWithPrice;
  onPress: (event: GestureResponderEvent) => void;
  isOpen: boolean;
  lat: number;
  lng: number;
};

function getPriceFromZone(zone: ZoneWithPrice, intervalMinutes: number) {
  return;
}

function formatMinutes(maxParkingTime: number) {
  return maxParkingTime > 60
    ? `${Math.round((maxParkingTime / 60) * 10) / 10}hr`
    : `${maxParkingTime}m`;
}

export function ZoneItem({ zone, onPress, isOpen }: Props) {
  const { maximumValue: maxParkingTime, minimumValue: minParkingTime } =
    zone.zoneDetails.zones[0].parkInfo.timeBlocks[0];

  let price = zone.priceDetails.price[1].totalPrice;
  let time = zone.priceDetails.maxParkingTime.totalMinutes;

  time = minParkingTime;

  // if (minParkingTime>)
  // if (maxParkingTime > 60) {
  //   const hours = maxParkingTime / 60;
  //   console.log("hour", hours);
  //   price /= hours;
  // }

  const maxInterval = Math.min(60, maxParkingTime);

  return (
    <>
      {isOpen ? (
        <LargeContainer onPressIn={onPress}>
          <LocationName>{zone.locationName}</LocationName>
          <ZoneInfo>Zone: {zone.signageCode}</ZoneInfo>
          <Price>
            ${price.toFixed(2)} / {maxInterval}m
          </Price>
          {minParkingTime !== maxParkingTime ? (
            <Price>
              {formatMinutes(minParkingTime)} - {formatMinutes(maxParkingTime)}
            </Price>
          ) : (
            <Price>{formatMinutes(minParkingTime)} max</Price>
          )}
        </LargeContainer>
      ) : (
        <PriceCircle onPressIn={onPress}>
          <CircleZoneCodeContainer>
            <CircleZoneCode>{zone.signageCode}</CircleZoneCode>
          </CircleZoneCodeContainer>
          <CirclePrice>
            ${price.toFixed(2)} / {time}m
          </CirclePrice>
        </PriceCircle>
      )}
    </>
  );
}
