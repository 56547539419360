import axios from "axios";
import { ZoneWithPrice } from "./types";
import { Region } from "react-native-maps";
// @ts-ignore
import TinyCache from "tinycache";
const myCache = new TinyCache();

const baseURL = `https://halfbold.com/park`;
// const baseURL = `http://localhost:8124`;

export async function getZonesForLocationAPI(
  region: Region
): Promise<ZoneWithPrice[]> {
  const cacheObj = myCache.get(JSON.stringify(region));
  if (cacheObj) {
    return cacheObj;
  }
  const response = await axios.post<ZoneWithPrice[]>(
    `${baseURL}/getZonesForLocation`,
    {
      region,
    }
  );

  myCache.put(JSON.stringify(region), response.data, 60 * 60 * 1000);
  return response.data;
}
